<template>
  <div class="about-container solution-container">
    <Header></Header>
    <div class="container">
      <div class="first">
        <h1 class="fs-64">主营业务</h1>
      </div>
      <div class="banner"></div>
      <div class="third">
        <div class="df ptb-100">
          <div><img src="../assets/img/product/p_2.jpg" alt="" /></div>
          <div>
            <h2 class="fs-38 mb-20">海运进出口业务</h2>
            <p class="fs-20">
              提供国际海运集装箱整箱货物、拼箱货物进出口代理运输服务。拥有完善的服务体系，
              为客户提供从订舱、拖车、报关、清关、仓储、代办货运保险等物流服务。
            </p>
          </div>
        </div>
        <div class="df ptb-100">
          <div>
            <h2 class="fs-38 mb-20">空运进出口</h2>
            <p class="fs-20">
              我们与多家知名航空公司保持着紧密的合作关系，可以提供多家航空公司的运输服务，
              同时具备完善的物流服务体系，能够为客户提供空运货物进出口服务。
            </p>
            <p class="fs-20 mt-10 mb-10">
              服务涵盖了各种货物类型，如科技产品、电子产品、服装、化工品、医疗器械等等，
              配备了专业化团队和现代化设备，保障货物顺利抵达目的地。
            </p>
            <p class="fs-20">
              我们始终致力于为客户提供优质的空运物流服务，
              借助先进的信息系统和网络平台，实时了解货物的行踪，提供及时准确的运输信息，使客户省心放心。
            </p>
          </div>
          <div class="text-right">
            <img src="../assets/img/product/p_1.jpg" alt="" />
          </div>
        </div>
       
        <div class="df ptb-100">
          <div>
            <h2 class="fs-38 mb-20">海运整箱拼箱</h2>
            <p class="fs-20">
              我们与多家拼箱代理公司建立了紧密的业务合作伙伴关系，
              可以为客户提供从货物托运、出口清关、海运托运、进口清关到国内配送等服务，
              力求为客户提供安全、便捷的拼箱服务。
            </p>
          </div>
          <div class="text-right">
            <img src="../assets/img/home/cb4.jpg" alt="" />
          </div>
        </div>
        <div class="df ptb-100">
          <div><img src="../assets/img/product/p_4.jpg" alt="" /></div>
          <div>
            <h2 class="fs-38 mb-20">国际快递</h2>
            <p class="fs-20">
              为客户提供安全、快捷的快递运输服务。
              全程追踪您的包裹，保障您的货品安全地抵达目的地。
            </p>
          </div>
        </div>
        <div class="df ptb-100">
          <div>
            <h2 class="fs-38 mb-20">生物冷链</h2>
            <p class="fs-20">
              生鲜类、医药类、肉类、乳品类等，并配有恒温仓库。
            </p>
          </div>
          <div class="text-right">
            <img src="../assets/img/product/s-3.png" alt="" />
          </div>
        </div>
        <div class="df ptb-100">
          <div><img src="../assets/img/product/s-6.png" alt="" /></div>
          <div>
            <h2 class="fs-38 mb-20">活体动物</h2>
            <p class="fs-20">
              活体动物包括家禽、鸟类、哺乳动物、爬行动物、鱼、昆虫、甲壳纲动物、贝壳类动物等。
            </p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "../components/header";
import Footer from "../components/footer";

export default {
  name: "Solution",
  components: {
    Header,
    Footer,
  },
};
</script>
